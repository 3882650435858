<template>
  <b-row>
    <b-col md="8">
      <calendar
          ref="calendar"
          @newCalendarDates="calendarDatesChange"

      />
    </b-col>
    <b-col md="4">
      <tasks
          :tasks="tasks"
          @updateCalendar="updateCalendar"/>
    </b-col>
  </b-row>

</template>

<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import useAPI from '@/utils/useAPI'
import i18n from '@/libs/i18n'

import Calendar from '../../components/home/calendar/Calendar'
import Tasks from '../../components/tasks/Tasks'

export default {
  components: {
    Tasks,
    Calendar,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const calendar = ref(null)
    const firstLoad = ref(true)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { tasks, workflows } = useAPI()

    // const x = computed( () => {
    //   console.log(
    //       store.getters['app/getLoaders']
    //   )
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchCalendars,
      fetchTasks,
      fetchWorkflows,
      fetchUsers
    } = useAPI()

    const updateCalendar = () => {
      calendar.value.$refs.calendar.refetchEvents()
    }

    const calendarDatesChange = (dates) => {
      fetchCalendars({dates: dates}).then(() => {
        firstLoad.value = false
      })

      if (firstLoad.value) {
        fetchCalendars({dates: dates})
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchTasks()
    fetchUsers().then(() => {
      fetchWorkflows()

      store.getters['user/getCurrentEmployees'].forEach(e => {
        if (!store.getters['calendar/getCalendars'].some(c => c.name == e._display)) {
          store.commit('calendar/ADD_CALENDAR', {
            name: e._display,
          })
        }
      })

    })

    return {
      // Components

      // Data
      calendar,

      // Computed
      tasks,
      workflows,
      // x,

      // Methods
      updateCalendar,
      calendarDatesChange,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>